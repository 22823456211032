import { useContext } from "react";
import { AppContext } from "../App";
import moment from "moment/moment";
import Label from "./Label";
import TimeRange from "./TimeRange";
import { currencyFormat } from "./functions";
import ExpireBox from "./ExpireBox";
import DeliveryIcon from "./icons/DeliveryIcon";
import TakeawayIcon from "./icons/TakeawayIcon";
import ClockIcon from "./icons/ClockIcon";
import PricelistIcon from "./icons/PricelistIcon";
import LocationIcon from "./icons/LocationIcon";
import EditIcon from "./icons/EditIcon";

function StoreHeader({ setPopupVisible }) {
	const { settings, cart, editCart, user, setConfirm, hasDelivery, hasTakeaway } =
		useContext(AppContext);

	return (
		<div id="store-header" className="screen-container">
			{cart.shop && cart.shop.img && (
				<div
					className="img"
					style={{ backgroundImage: "url(" + cart.shop.img + ")" }}
				></div>
			)}

			<div className="content">
				{cart.shop && (
					<div className="store-header-title">
						<div className="title" onClick={() => setPopupVisible(true)}>
							<EditIcon></EditIcon>
							{cart.shop.name}
						</div>
						<div className="small smoot">
							{" " +
								cart.shop.address +
								" " +
								cart.shop.number +
								", " +
								cart.shop.city}
						</div>
						{cart.hotel_id && (
							<button
								onClick={() =>
									setConfirm({
										title: <Label number={38} />,
										onConfirm: async () => {
											localStorage.removeItem("website-hotel-id");
											editCart({ hotel_id: null });
										},
									})
								}
								className="button"
							>
								<Label number={200} />
							</button>
						)}
					</div>
				)}
				{!cart.hotel_id && hasDelivery == 1 && hasTakeaway == 1 && (
					<div>
						<div className="delivery-type-selector">
							{cart.delivery_type == "delivery" ? (
								<button onClick={() => setPopupVisible(true)} className="active">
									<DeliveryIcon></DeliveryIcon>
									<div>
										<Label number={7} />
									</div>
								</button>
							) : (
								<button onClick={() => editCart({ delivery_type: "delivery" })}>
									<DeliveryIcon></DeliveryIcon>
									<div>
										<Label number={7} />
									</div>
								</button>
							)}
							{cart.delivery_type == "takeaway" ? (
								<button onClick={() => setPopupVisible(true)} className="active">
									<TakeawayIcon></TakeawayIcon>
									<div>
										<Label number={8} />
									</div>
								</button>
							) : (
								<button onClick={() => editCart({ delivery_type: "takeaway" })}>
									<TakeawayIcon></TakeawayIcon>
									<div>
										<Label number={8} />
									</div>
								</button>
							)}
						</div>
					</div>
				)}
				<div className="lines-container" onClick={() => setPopupVisible(true)}>
					{hasDelivery == 0 && (
						<div className="icon-line">
							<LocationIcon></LocationIcon>
							<div>
								<Label number={5} />: <Label number={8} />
							</div>
						</div>
					)}
					{hasTakeaway == 0 && (
						<div className="icon-line">
							<LocationIcon></LocationIcon>
							<div>
								<Label number={5} />: <Label number={7} />
							</div>
						</div>
					)}
					{cart.delivery_type == "delivery" && user && cart.delivery_address && (
						<div className="icon-line">
							<LocationIcon></LocationIcon>
							<div>
								{cart.hotel_id && cart.hotel.name + " - "}
								{cart.delivery_address.address +
									" " +
									cart.delivery_address.number +
									", " +
									cart.delivery_address.city}
							</div>
						</div>
					)}
					{cart.delivery_type == "delivery" && !user && (
						<div className="icon-line">
							<LocationIcon></LocationIcon>
							<div>
								{cart.hotel_id
									? cart.hotel.name +
									  " - " +
									  cart.hotel.address +
									  " " +
									  cart.hotel.number +
									  ", " +
									  cart.hotel.city
									: cart.temp_address &&
									  cart.temp_address.address +
											" " +
											cart.temp_address.number +
											", " +
											cart.temp_address.city +
											(cart.temp_address.doorphone
												? " (" + cart.temp_address.doorphone + ")"
												: "")}
							</div>
						</div>
					)}
					{cart.delivery_type == "delivery" && (
						<div className="icon-line">
							<DeliveryIcon></DeliveryIcon>
							<div>
								<Label number={14} />:{" "}
								{currencyFormat(cart.delivery_price, cart?.shop?.currency)}
							</div>
						</div>
					)}
					{cart.delivery_date && (
						<div className="icon-line">
							<ClockIcon></ClockIcon>
							<div>
								{moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
									"DD/MM/YYYY"
								)}{" "}
								<Label number={77} />:{" "}
								<TimeRange
									date={cart.delivery_date}
									range={cart.delivery_time_range}
								/>
								<ExpireBox />
							</div>
						</div>
					)}
					{settings.website.show_list == 1 && cart.list_id && (
						<div className="icon-line">
							<PricelistIcon></PricelistIcon>
							<div>
								<Label number={165} />:{" " + cart.list.name}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default StoreHeader;
