import { useContext } from "react";
import { AppContext } from "../App";
import PopUp from "../components/PopUp";
import Label from "../components/Label";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "../components/icons/CloseIcon";

function LoginPopup() {
	const { settings, setLoginPopup, track, setLoginRedirect, cart } = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<PopUp size="sm">
			<div className="header">
				<div></div>
				<button onClick={() => setLoginPopup(false)}>
					<CloseIcon></CloseIcon>
				</button>
			</div>
			<div className="content">
				<div className="text-center">
					<Label number={145} />
				</div>
				<button
					className="button button-primary"
					onClick={() => {
						setLoginPopup(false);
						setLoginRedirect(location.pathname);
						navigate("/login");
					}}
				>
					<Label number={3} />
				</button>
				{cart &&
					(settings.website?.guest_mode == 1 ||
						(cart.hotel_id == 1 && cart.hotel.skip_login != 1)) && (
						<>
							<div className="text-center">
								<Label number={144} />
							</div>
							<button
								className="button button-primary"
								onClick={() => {
									setLoginPopup(false);
									track();
									navigate("/checkout");
								}}
							>
								<Label number={146} />
							</button>
						</>
					)}
				{cart && cart.hotel_id == 1 && cart.hotel.skip_login == 1 && (
					<>
						<div className="text-center">
							<Label number={144} />
						</div>
						<button
							className="button button-primary"
							onClick={() => {
								setLoginPopup(false);
								track();
								navigate("/check_hotel");
							}}
						>
							<Label number={146} />
						</button>
					</>
				)}
			</div>
		</PopUp>
	);
}

export default LoginPopup;
