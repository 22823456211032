import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext } from "react";
import { AppContext } from "../App";
import Form from "../components/form";

function CheckCodeHotel() {
	const { setLoading, setConfirm, tempUser, editCart } = useContext(AppContext);
	const navigate = useNavigate();

	const handleCheckCode = async (request) => {
		setLoading(true);
		request.mode = "hotel";
		const loginRes = await api.post(
			"/customers/verify_confirm_code/" + tempUser.id + "/",
			request
		);
		setLoading(false);
		if (loginRes.success == 0)
			setConfirm({
				title: "Errore",
				message: loginRes.error,
			});
		else {
			editCart({
				mobile: tempUser.mobile,
				area_code: tempUser.area_code,
			});
			navigate("/checkout");
		}
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<h1>
						<Label number={126} />
					</h1>
					<p>
						<Label number={192} />
					</p>
					<Form
						onSubmit={handleCheckCode}
						fields={[
							{
								type: "number",
								name: "code",
								label: <Label number={128} />,
								value: "",
								required: true,
							},
						]}
						submitButtonLabel={<Label number={41} />}
					/>
				</div>
			</div>
		</div>
	);
}

export default CheckCodeHotel;
