import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext } from "react";
import { AppContext } from "../App";
import { textLabel } from "../components/functions";
import areaCodes from "../data/areaCodes.json";

function LoginCheckHotel() {
	const { setLoading, setConfirm, tempUser, setTempUser, labels, lang } = useContext(AppContext);
	const navigate = useNavigate();

	const checkEmail = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		setLoading(true);
		const loginRes = await api.post("/customers/verify_mobile/", request);
		setLoading(false);
		if (loginRes.success == 0)
			setConfirm({
				title: "Errore",
				message: loginRes.error,
			});
		else {
			setTempUser({ ...tempUser, id: loginRes.id });
			navigate("/check_code_hotel");
		}
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<div>
						<h1>
							<Label number={211} />
						</h1>
					</div>
					<form onSubmit={checkEmail}>
						<div className="mobile-container">
							<select
								name="area_code"
								value={tempUser.area_code}
								onChange={(e) =>
									setTempUser({ ...tempUser, area_code: e.target.value })
								}
							>
								<option value=""></option>
								{areaCodes.map((el, i) => (
									<option key={i} value={el.dial_code}>
										{el.code + " (" + el.dial_code + ")"}
									</option>
								))}
							</select>
							<input
								type="tel"
								name="mobile"
								placeholder={textLabel(labels, 56, lang)}
								value={tempUser.mobile}
								onChange={(e) =>
									setTempUser({ ...tempUser, mobile: e.target.value })
								}
								required
							/>
						</div>
						<button className="button button-primary">
							<Label number={41} />
						</button>
						<button type="button" className="button" onClick={() => navigate("/store")}>
							<Label number={131} />
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginCheckHotel;
