import { useContext } from "react";
import Label from "./Label";
import { AppContext } from "../App";

function ProductLabels({ product, discounted = false }) {
	const { cart } = useContext(AppContext);

	const hasCashback = (product) => {
		if (
			cart &&
			cart.shop_id &&
			product.promo &&
			product.promo.cashback &&
			product.promo.cashback[cart.shop_id]?.type &&
			product.promo.cashback[cart.shop_id]?.value > 0
		)
			return true;
		return false;
	};

	const getCashbackValue = (product) => {
		if (product.promo.cashback[cart.shop_id]?.type == "percent")
			return product.promo.cashback[cart.shop_id]?.value + "%";
		else return product.promo.cashback[cart.shop_id]?.value + "€";
	};

	return (
		<div className="badge-container">
			{product.new == 1 && (
				<div className="label-new">
					<Label number={24} />
				</div>
			)}
			{product.best_sell == 1 && (
				<div className="label-sell">
					<Label number={25} />
				</div>
			)}
			{product.vegan == 1 && (
				<div className="label-vegan">
					<Label number={26} />
				</div>
			)}
			{product.gluten_free == 1 && (
				<div className="label-gluten">
					<Label number={27} />
				</div>
			)}
			{product.disabled == 1 && (
				<div className="label-available">
					<Label number={28} />
				</div>
			)}
			{discounted == 1 && (
				<div className="label-promo">
					<Label number={174} />
				</div>
			)}
			{product.custom_label == 1 && (
				<div className="label-custom">
					<Label number={179} />
				</div>
			)}
			{hasCashback(product) && (
				<div>
					<Label number={213} />
					{" " + getCashbackValue(product)}
				</div>
			)}
		</div>
	);
}

export default ProductLabels;
