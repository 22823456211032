import { useContext } from "react";
import { AppContext } from "../App";
import { currencyFormat } from "./functions";
import Label from "./Label";
import PlusMinus from "./PlusMinus";
import { useNavigate } from "react-router-dom";
import PromoLevels from "./PromoLevels";

function Cart({ cartEnabled, setCartEnabled }) {
	const { cart, handleEditProduct, user, setLoginPopup, emptyCart, track } =
		useContext(AppContext);
	const navigate = useNavigate();

	const toggleCart = (mode) => {
		const el = document.getElementById("cart");
		if (mode) el.style.display = "block";
		else el.style.display = null;
	};

	if (!cart) return null;

	return (
		<>
			<div id="cart">
				<div className="container">
					<div className="close-button">
						<button onClick={() => toggleCart(false)}>x</button>
					</div>
					<PromoLevels />
					<div className="content">
						<div className="title">
							<Label number={23} />
						</div>
						{cart.products.map((product, i) => (
							<div key={i} className="line">
								<div>
									{product.name}
									<div className="small smoot">
										{product.variants.map((variant, i) => (
											<div key={i}>{variant.qty + "x " + variant.name}</div>
										))}
										{product.products?.map((subproduct, i) => (
											<div key={i}>
												{subproduct.qty + "x " + subproduct.name}
											</div>
										))}
										{product.notes && (
											<div
												dangerouslySetInnerHTML={{ __html: product.notes }}
											/>
										)}
									</div>
								</div>
								<div>
									{product.is_removable != 0 && (
										<PlusMinus
											qty={product.qty}
											onCange={(qty) => handleEditProduct(product, qty)}
											qtyValue={
												product.qty_unsplitted
													? product.qty_unsplitted
													: product.qty
											}
										/>
									)}
								</div>
								<div>
									{product.discounted == 1 ? (
										<div>
											<span className="small smoot">
												<s>
													{currencyFormat(
														product?.original_price,
														cart?.shop?.currency
													)}
												</s>{" "}
											</span>
											{currencyFormat(product.total, cart?.shop?.currency)}
										</div>
									) : (
										<div>
											{currencyFormat(product.total, cart?.shop?.currency)}
										</div>
									)}
								</div>
							</div>
						))}
						{cart.discounts_code?.length > 0 &&
							cart.discounts_code.map((discount, i) => (
								<div key={i}>
									<div className="line subtotal">
										<div>
											<Label number={13} />
										</div>
										<div></div>
										<div>
											{currencyFormat(
												discount.total_before_discount,
												cart?.shop?.currency
											)}
										</div>
									</div>
									<div className="line">
										<div className="icon">{discount.name}</div>
										<div></div>
										<div>
											-{currencyFormat(discount.price, cart?.shop?.currency)}
										</div>
									</div>
								</div>
							))}
						{cart.delivery_type == "delivery" && (
							<>
								<div className="line subtotal">
									<div>
										<Label number={13} />
									</div>
									<div></div>
									<div>
										{currencyFormat(
											cart.total_before_delivery_price,
											cart?.shop?.currency
										)}
									</div>
								</div>
								<div className="line">
									<div>
										<Label number={14} />
									</div>
									<div></div>
									<div>
										{currencyFormat(cart.delivery_price, cart?.shop?.currency)}
									</div>
								</div>
							</>
						)}
						<div className="line total">
							<div>
								<Label number={15} />
							</div>
							<div></div>
							<div>{currencyFormat(cart.total, cart?.shop?.currency)}</div>
						</div>
						{cart.order_min && cart.order_min > 0 && (
							<div className="line small">
								<div>
									<Label number={166} />
								</div>
								<div></div>
								<div>{currencyFormat(cart.order_min, cart?.shop?.currency)}</div>
							</div>
						)}
						{cart.delivery_type == "delivery" &&
							cart.delivery_free_min &&
							cart.delivery_free_min > 0 && (
								<div className="line small">
									<div>
										<Label number={167} />
									</div>
									<div></div>
									<div>
										{currencyFormat(
											cart.delivery_free_min,
											cart?.shop?.currency
										)}
									</div>
								</div>
							)}
					</div>
					<div className="footer">
						<button
							className="button button-primary button-block mb-2"
							onClick={() => {
								if (!cartEnabled) setCartEnabled(true);
								else if (!user) setLoginPopup(true);
								else {
									navigate("/checkout");
									track();
								}
							}}
						>
							<Label number={16} />
						</button>
						<button
							className="button button-secondary button-block"
							onClick={() => emptyCart()}
						>
							<Label number={173} />
						</button>
					</div>
				</div>
			</div>
			<div className="checkout-fixed">
				<button onClick={() => toggleCart(true)}>
					<Label number={73} /> - {currencyFormat(cart.total, cart?.shop?.currency)}
				</button>
				<PromoLevels mode="slim" />
			</div>
		</>
	);
}

export default Cart;
